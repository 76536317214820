.groupList {
  max-width: 350px;
  width: 100%;
}

.groupCompetitionPlaceBadge {
  position: absolute;
  left: -40px;
}

.actionLogsHeader {
  background-color: #556de6;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: white;
}

.actionLogsList {
  border: 3px solid #556de6;
  border-radius: 0;

  .activity-logs {
    li:after {
      content: "/";
      position: absolute;
      margin: 0 5px;
      opacity: 0.3;
    }

    li:last-of-type:after {
      display: none;
    }
  }
}

.scrollButton {
  width: 50px;
  font-size: 50px;
  position: relative;
  background-color: transparent;
  p {
    position: fixed;
    color: #5fccc7;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}

.rotate180deg {
  rotate: 180deg;
}

.Matches {
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  margin: 30px 0;
  padding-left: 3rem;

  &--with-qualifications {
    padding-left: 680px;
  }

  .multipleQualificationsSecondMatch {
    transform: translateY(120px);
  }

  .multipleQualificationsFirstMatch {
    transform: translateY(-120px);
  }

  .tbaMatch {
    min-width: 420px;
    height: 191px !important;
    background: #3b3f4d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      color: white;
    }
  }

  .Match {
    min-width: 420px;
    margin: 205px 0; //85 old px
    position: relative;
    margin-right: 60px;

    .settings,
    .position {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 5px lightblue;
      color: gold;
      font-weight: bold;
      top: calc(50% - 20px);
      left: -25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      z-index: 1;
      padding-right: 10px;
    }

    .settings {
      left: calc(100% - 15px);
      color: #495057;
      padding-left: 15px;
      cursor: pointer;

      &--disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    .disabledSettings {
      background-color: #696969;
      color: #fff;
      cursor: not-allowed;
    }

    &__future-stages {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &__content {
      position: relative;
      background: #3b3f4d;
      padding: 5px;
      border-radius: 5px;
      z-index: 2;
    }

    > div {
      > div:last-child {
        .slot {
          margin-bottom: 0;
        }
      }
    }

    .score {
      font-size: 16px;
      color: lightgrey;
      margin: 0 5px;
      span {
        margin: 0 5px;

        &.tie {
          font-size: 12px;
          margin-top: -5px;
          display: block;
          text-align: center;
          color: cyan;
        }
      }
    }

    .addPlayer {
      height: 68px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      img {
        width: 50px;
        margin-left: 20px;
      }
      h3 {
        color: #3b3f4d;
        margin-bottom: 0px;
        margin-left: 20px;
      }
    }

    &__stage {
      position: relative;
    }

    &__qualification {
      position: absolute;
      right: calc(100% + 60px);
      margin: 0;
    }
  }
}

.Match__score {
  margin: 20px 0;
  label {
    width: 100%;
  }
}

.slot {
  &.winner {
    background: #4ff4b8;
  }
  &.bye {
    background: #f5b1b1;
  }
}

.tournament-brackets {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 50px;
}

.bracket {
  padding-left: 0;
  display: flex;
  margin: 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  border-right: 1px dashed #ccc;
  flex: 1;
}

.team-item {
  margin: 0.5rem 10px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 22px !important;
}

.team-item:after {
  content: "";
  border-color: #3b3f4d;
  border-width: 2px;
  position: absolute;
  display: block;
  width: 100px;
  right: -11px; // -11px
}

.team-item:nth-of-type(odd):after {
  border-right-style: solid;
  border-top-style: solid;
  height: 100%;
  top: 50%;
}

.team-item:nth-of-type(even):after {
  border-right-style: solid;
  border-bottom-style: solid;
  height: 100%;
  top: -50%;
}

.team-item:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 10px;
  left: -10px;
  top: 50%;
}

.bracket-2 {
  .team-item:nth-of-type(odd):after {
    height: 200%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 200%;
    top: -150%;
  }
}

.bracket-3 {
  .team-item:nth-of-type(odd):after {
    height: 500%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 350%;
    top: -300%;
  }
}

.bracket-4 {
  .team-item:nth-of-type(odd):after {
    height: 700%;
    top: 50%;
  }
  .team-item:nth-of-type(even):after {
    height: 700%;
    top: -650%;
  }
}

.bracket:first-of-type {
  .team-item:before {
    display: none;
  }
}

.bracket-4 {
  .team-item:after {
    display: none;
  }
}

.bracket:last-of-type {
  .team-item:before,
  .team-item:after {
    display: none;
  }
}

.qualMatch {
  position: absolute !important;
  top: 30px;
  left: -660px;
}

.firstQualMatch {
  transform: translateY(140px);
}

.secondQualMatch {
  transform: translateY(-140px);
}
