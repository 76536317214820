.disabledRow {
  background-color: #dcdcdc;
}

.disabledRow:hover {
  background-color: #dcdcdc !important;
}

.assignedClubSelect {
  display: none;
}

.ellips {
  display: flex;
  max-width: 175px;
  width: fit-content;
  overflow: hidden;

  > * {
    margin-bottom: 0px;
    margin-right: 5px;
  }
}

.reactPagination {
  .pagination {
    justify-content: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    width: 30px;
    display: flex;
    justify-content: center;
    font-size: 25px;
  }

  ul.pagination li a {
    text-decoration: none;
    color: #75788c;
    font-size: 12px;
  }

  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    border-radius: 200px;
    background-color: #556de6;
  }

  ul.pagination li a:hover,
  ul.pagination li a.active {
    border-radius: 200px;
  }

  .page-selection {
    width: 48px;
    height: 30px;
    color: #556de6;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.disabledNavLink {
  cursor: default !important;
  span {
    color: #696969;
  }
  i {
    color: #696969 !important;
  }
  i::before {
    color: #696969 !important;
  }
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.filter-area {
  position: relative;

  .search-label {
    width: 100%;
  }

  .bx-filter-alt {
    cursor: pointer;
    padding: 5px;
  }

  .bx-reset {
    cursor: pointer;
    padding: 5px;
  }
}

table.align-middle {
  td {
    vertical-align: middle;
  }
}

th.sortable {
  outline: none;
  cursor: pointer;

  .order-4,
  .caret-4-asc,
  .caret-4-desc {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 5px;
  }

  .order-4:before {
    content: "\F04BA";
  }

  .caret-4-asc:before {
    content: "\F04BC";
  }

  .caret-4-desc:before {
    content: "\F04BD";
  }
}

.FileUpload {
  &.profile-picture {
    width: 60px;
    height: 60px;
    min-height: auto;
    cursor: pointer;

    .form-group {
      display: none;
    }

    .dropzone {
      min-height: auto;
      padding: 0;
    }

    .dz-message {
      margin-top: 0 !important;
      padding: 0;

      img {
        padding: 3px;
      }
    }
  }
}

.page-content {
  min-height: 100vh;
}

.score-status {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: grey;
  margin: 0 0 0 3px;
  color: #fff;
  &.grey {
    background: #D3D3D3;
  }
  &.red {
    background: #F42B03;
  }
  &.orange {
    background: #FFC803;
  }
  &.green {
    background: #00DB30;
  }
}

.react-datepicker__close-icon::after {
  background-color: unset !important;
  color: #d3d3d3 !important;
  font-size: 24px !important;
}

.exports-dates-pickers {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
