@import "./components/Tabs/Competitions/Tabs/Matches/style";

.pointer {
  cursor: pointer;
}

.hourDatePicker {
  max-width: 150px;
  outline: none;
  height: 36px;
  border-radius: 5px;
  width: 100%;
}

.no-events {
  padding: 12px;
}

.phaseCol {
  min-width: 370px;
}

.nav {
  background-color: white;
  padding: 10px;
  margin-bottom: 15px;
}

.overflowScroll {
  overflow: scroll;
}

.featuredWrap {
  width: 380px;
  height: 210px;
  .dz-message {
    margin: 0 !important;
    height: 210px !important;
  }

  .dropzone {
    min-height: 210px !important;
  }
}

.prevent-click {
  pointer-events: none;
}

.coverWrap {
  width: 1200px;
  height: 500px;
  .dz-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px !important;
    margin: 0 !important;
  }
}

.logoWrap {
  width: 180px;
  .dz-message {
    height: 180px !important;
    margin: 0 !important;
  }
  .dropzone {
    min-height: 180px !important;
    img {
      height: 180px;
    }
  }
  h4 {
    font-size: 15px;
  }
}

.pointsCard {
  display: flex;
  justify-content: space-between;
  color: #02475e;
  font-weight: bold;
  max-width: 70px;
  input {
    background: #02475e;
    transition: all 0.5s ease;
    color: #fff;
    font-weight: bold;
  }
}

.shadow {
  -webkit-box-shadow: 10px 13px 24px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 13px 24px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 13px 24px -4px rgba(0, 0, 0, 0.75);
}

.competitionWinners {
  font-weight: bold;
}

.project-list-table tr.event {
  &--disabled {
    background: rgba(255, 255, 255, 0.7);

    td {
      opacity: 0.4;
    }
  }

  .actions {
    opacity: 1;
    a {
      margin-right: 3px;
      margin-left: 3px;
    }
  }

  .name {
    color: $gray-700;
  }

  i {
    font-size: 16px;
  }

  a {
    text-decoration: none;
  }

  .button--enable {
    color: greenyellow;
  }

  .button--disable {
    color: red;
  }
}

.addedBy {
  background-color: #e0ffff;
  padding: 2px;
  position: absolute;
  // right: 0;
  left: 0;
  bottom: 0;
  margin: 0 !important;
  max-width: 290px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  .createdByTxt {
    margin-right: 3px;
    font-style: italic;
    font-size: 10px;
  }
}

.pendingStatus {
  position: absolute;
  right: 0px;
  bottom: 0;
  margin: 0px;
  border: 1px solid #3f729b;
  background-color: #3f729b;
  border-top-left-radius: 12px;
  p {
    margin: 0px;
    padding: 5px;
    color: white;
  }
}

.contentEllipsis {
  display: block;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slot__wildCard {
  background: lightcyan;
  position: relative;
  overflow: hidden;

  .wildCard {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 85px;

    span {
      display: block;
      text-align: center;
      background: palevioletred;
      color: #fff;
    }
  }
}

.emptySlotHeight {
  height: 84px;
}

.slot {
  margin-bottom: 5px;

  &__waiting {
    background: lightyellow;
  }

  .card-body {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .position {
    cursor: pointer;
    background: #02475e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    margin-right: 10px;
  }

  .partner {
    border-top: 0.1px solid lightgrey;
    padding-top: 5px;
    margin-top: 5px;
  }
}

.wildcard__action {
  cursor: pointer;
}

.overflowscroll {
  display: flex;
  overflow: scroll;
  width: 100%;
}

.duoLuckyDraw {
  .qualifications {
    > div:first-child {
      .slot {
        &::before {
          height: 135px !important;
        }
      }
    }
  }
}

.LuckyDraw {
  &__qualifications {
    position: relative;
    // padding-left: 470px;
    padding-left: 550px;
  }
  .slot__wrapper {
    &--qualifications {
      height: 142px;
      margin-top: 100px !important;
      margin-bottom: 100px !important;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: block;
        width: 25px;
        height: 2px;
        background: #02475e;
        position: absolute;
        left: -25px;
        top: 50%;
      }
    }

    &--spacing {
      margin-bottom: 30px;
    }
  }

  .slot {
    // max-width: 420px;
    max-width: 475px;
    min-width: 475px;
    min-height: 66px;
  }

  .slot__wrapper {
    position: relative;
  }

  .qualifications {
    position: absolute;
    // left: -470px;
    left: -520px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    .slot {
      &::after {
        content: "";
        display: block;
        width: 22px;
        height: 2px;
        background: #02475e;
        position: absolute;
        right: -22px;
        top: 50%;
      }
    }

    > div:first-child {
      .slot {
        &::before {
          content: "";
          display: block;
          height: 71px;
          width: 2px;
          background: #02475e;
          position: absolute;
          right: -22px;
          top: 50%;
        }
      }
    }
  }
}

.Event_phases {
  .error {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .form-group {
    input:disabled {
      opacity: 0.3;
    }
  }
}

.removeFees {
  right: 20px;
  top: 21px;
}

.defaultFees {
  right: 20px;
  top: 45px;
}
