
//
// Datepicker
//

.datepicker {
  border: 1px solid $border-color;
  padding: 8px;
  z-index: 999 !important;
  table{
    tr{
      th{
        font-weight: 500;
      }
      td{
        &.active, &.active:hover, .active.disabled, &.active.disabled:hover,
        &.today,  &.today:hover, &.today.disabled, &.today.disabled:hover, 
        &.selected, &.selected:hover, &.selected.disabled, &.selected.disabled:hover{
          background-color: $primary !important;
          background-image: none;
          box-shadow: none;
          color: $white !important;
        }

        &.day.focused,
        &.day:hover,
        span.focused,
        span:hover {
            background: $gray-200;
        }

        &.new,
        &.old,
        span.new,
        span.old {
            color: $gray-500;
            opacity: 0.6;
        }

        &.range, &.range.disabled, &.range.disabled:hover, &.range:hover{
            background-color: $gray-300;
        }
      }
    }
  }
}

.table-condensed{
  >thead>tr>th, >tbody>tr>td {
    padding: 7px;
  }
}

.bootstrap-datepicker-inline{
  .datepicker-inline{
    width: auto !important;
    display: inline-block;
  }
}



// DATEPICKER

.datepicker-container{
  border: 1px solid $border-color;
  box-shadow: none;
  background-color: $dropdown-bg;

  &.datepicker-inline{
    width: 212px;
  }
}

.datepicker-panel{
  
  >ul{
    >li{
      background-color: $dropdown-bg;
      border-radius: 4px;

      &.picked, &.picked:hover{
        background-color: rgba($primary, 0.25);
        color: $primary;
      }

      &.highlighted, &.highlighted:hover, &:hover{
        background-color: $primary;
        color: $white;
      }

      
      &.muted, &.muted:hover{
        color: $gray-500;
        opacity: 0.6;
      }
    }

    

    &[data-view=week]{
      >li{
        font-weight: $font-weight-medium;
      }

      >li, >li:hover{
        background-color: $dropdown-bg;
      }
    }
  }
}

.datepicker-wrapper {
  position: relative;
  width: 100%;

  .react-datepicker {
    z-index: 9;
    display: flex;
    flex-direction: column-reverse;

    &__tab-loop {
      position: absolute;
    }

    &__input-container {
      display: block;

      input {
        background: white;
        border-radius: 4px;
        border: 1px solid #ced4da;
        height: 40px;
        width: 100%;
        padding: 10px;
      }
    }
  }

//'&__input-container': {
//  display: 'block',
//  input: {
//    background: 'white',
//    borderRadius: '4px',
//    border: '1px solid #7CC9C6',
//    height: '40px',
//    outline: 'none',
//    fontFamily: '"DM Sans", Arial, serif',
//    maxWidth: '100%',
//    padding: '10px',
//    width: '100%',
//  },
//
//"&:after": {
//  content: '"."',
//  color: "transparent",
//  position: "absolute",
//  right: 15,
//  top: 10,
//  backgroundImage: `url(${calendar.src})`,
//  width: calendar.width,
//  height: calendar.height,
//}
//}
//}
}
