// Classes
.flex {
  display: flex;

  &-align-items-center {
    align-items: center;
  }

  &-justify-content {
    &-between {
      justify-content: space-between;
    }
  }
}

.color {
  &-gold {
    color: gold;
  }
  &-grey {
    color: lightgrey;
  }
  &-purple {
    color: #556ee6;
  }
  &-orange {
    color: #ffab73;
  }
  &-yellow {
    color: #fff9b0;
  }
  &-darkblue {
    color: #02475e;
  }
  &-violetred {
    color: palevioletred;
  }
}

.fs {
  &-10 {
    font-size: 10px;
  }
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-18 {
    font-size: 18px;
  }
}

.min {
  &-height {
    &-40 {
      min-height: 40px;
    }
  }
}

.max {
  &-width {
    &-50 {
      max-width: 50px;
    }
  }
}


.gap-10 {
  gap: 10px;
}