.pb-24 {
    padding-bottom: 24px;
}

.h-100 {
    height: 100%;
}

.ml-60 {
    margin-left: -60px;
}